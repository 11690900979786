<template>
  <td>
    <v-layout column align-start justify-center>
      <ServiceLabel :color="color">{{ defaultValue }}</ServiceLabel>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined
    },
    item: {
      default: undefined
    },
    value: {}
  },
  components: {
    ServiceLabel: () => import("../text/ServiceLabel")
  },
  computed: {
    defaultValue() {
      if (this.value) {
        return this.value;
      } else if (this.item == undefined || this.header == undefined) {
        return "";
      } else {
        return this.item[this.header.value];
      }
    },
    color() {
      if (this.item) {
        return this.item["color"];
      } else {
        return 0;
      }
    }
  }
};
</script>
